import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button, Text } from '@fluentui/react-components';
import * as microsoftTeams from '@microsoft/teams-js';
import * as api from 'shared-fe-components/src/api';
import { EdoFlow } from './Edo';
import { EidEasyFlow } from './EidEasy/EidEasyFlow';
import { MszafirFlow } from './Mszafir';
import { IdNowFlow } from './IdNow';
import { SimplySignFlow } from './SimplySign/SimplySignFlow';
import { NativeFlow } from './Native';
import { LoadingScreen } from '../Common/LoadingScreen';
import { t } from '@lingui/macro';
import { ProviderSelection } from 'shared-fe-components/src/providers/ProviderSelection';
import { ErrorScreen, SuccessScreen } from 'shared-fe-components/src/common/StatusScreens';
import { useGraphApi } from '../../lib/useGraph';
import { TaskContainer } from '../Common/TaskContainer';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { SignTaskContextProvider, useSignTaskContext } from './SignTaskContext';
import { DummyProvider } from './DummyProvider';
import { useConfigurationContext, useUserContext } from 'contexts';
import { CancelSigningProcessIcon } from 'shared-fe-components/src/common/Icons';
import './SignTask.scss';

const CancelConfirmation = ({ hideCancelConfirmation, submitTaskCancel }) => {
  return (
    <WizardBox className="sign-task-cancel-confirmation-screen background-task" role="alert">
      <div />
      <div className="prompt-content-wrapper">
        <div className="prompt-content">
          <CancelSigningProcessIcon color="var(--colorScheme-colorNeutralForeground1)" />
          <div className="prompt-content-message">
            <Text as="h1" weight="bold">
              {t({ id: 'CancelConfirmation.Sign.Title' })}
            </Text>
            <Text size={400}>{t({ id: 'CancelConfirmation.WontBeSaved' })}</Text>
          </div>
        </div>
      </div>
      <div>
        <Button onClick={hideCancelConfirmation}>{t({ id: 'Common.No' })}</Button>
        <Button appearance="primary" onClick={submitTaskCancel}>
          {t({ id: 'CancelConfirmation.Sign.CancelButton' })}
        </Button>
      </div>
    </WizardBox>
  );
};

export const SignTask = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const givenProcessName = searchParams.get('processName');
  const selfSignParameters = useRef(null);
  const signParameters = useRef(null);
  const [step, setStep] = useState(null);
  const [cancelConfirmationVisible, setCancelConfirmationVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [xadesFormat, setXadesFormat] = useState(() => {
    const format = searchParams.get('xadesFormat');
    return format === 'undefined' ? null : format;
  });
  const signedFileUrl = useRef(null);
  const [loading, setLoading] = useState(true);
  const [errorCode, setErrorCode] = useState(null);
  const [initialProvider, setInitialProvider] = useState(null);
  const { getFileInfoAsync } = useGraphApi();
  const [isSigningOnMeeting, setIsSigningOnMeeting] = useState(false);

  const submitTaskBack = useCallback(() => {
    microsoftTeams.dialog.url.submit({ submitReason: 'back' });
  }, []);

  const submitTaskCancel = useCallback(() => {
    microsoftTeams.dialog.url.submit({ submitReason: 'cancel' });
  }, []);

  const submitTaskSuccess = useCallback(() => {
    microsoftTeams.dialog.url.submit({ submitReason: 'success', signedFileUrl: signedFileUrl.current });
  }, []);

  const showSuccessScreen = useCallback((fileUrl) => {
    signedFileUrl.current = fileUrl;
    setStep('success');
  }, []);

  const showCancelConfirmation = () => {
    setCancelConfirmationVisible(true);
  };

  const hideCancelConfirmation = () => {
    setCancelConfirmationVisible(false);
  };


  useEffect(() => {
    (async () => {
      if (params.sessionId && params.signatureId) {
        signParameters.current = { selfSign: false, sessionId: params.sessionId, signatureId: params.signatureId, xadesFormat: xadesFormat };
        let data, error;
        try {
          // All the signing on the Meeting
          data = await api.signing.getSignatureStatus(params.sessionId, params.signatureId);
          setXadesFormat(data.xadesFormat);
          setFile({
            name: data.document.fileName,
            size: data.document.fileSize,
          });
          setIsSigningOnMeeting(data.signingOnMeeting);
        } catch (err) {
          error = err;
        }
        const desc = api.signing.describeSessionFromGetSignatureStatus({ data, error });
        setErrorCode(desc.errorCode);
        setInitialProvider(desc.dummyProvider?.replace(/^/, '_dummy:'));
        if (data?.status === 'Succeeded') {
          showSuccessScreen(data.document?.signedFileDownloadInfo?.fileUrl ?? data.document?.downloadUrl);
        }
        setLoading(false);
      } else if (params.containerId && params.fileId) {
        // File added from the device
        setFile({
          name: searchParams.get('fileName'),
        });
        selfSignParameters.current = {
          selfSign: true,
          source: {
            type: 'StorageContainer',
            storageContainerSource: {
              driveId: params.containerId,
              containerId: params.containerId,
              documentId: params.fileId,
              fileId: params.fileId,
            },
          },
          name: givenProcessName,
          xadesFormat: xadesFormat
        };

        setLoading(false);
      } else if (params.driveId && params.folderId && params.fileId) {
        // File added from Teams or OneDrive
        selfSignParameters.current = {
          selfSign: true,
          source: { driveId: params.driveId, folderId: params.folderId, fileId: params.fileId },
          name: givenProcessName,
          xadesFormat: xadesFormat
        };

        getFileInfoAsync(params.driveId, params.fileId)
          .then((response) => {
            setFile(response);
          })
          .catch((error) => {
            setErrorCode(error.message ?? '');
            setStep('error');
          })
          .finally(() => setLoading(false));
      } else {
        setErrorCode('InvalidTask');
        setLoading(false);
      }
    })();
  }, [getFileInfoAsync, givenProcessName, params, showSuccessScreen]);

  const contextProps = useMemo(
    () => ({
      file,
      signParameters,
      selfSignParameters,
      xadesFormat: xadesFormat,
      onBack: submitTaskBack,
      onCancel: showCancelConfirmation,
      goToSuccess: showSuccessScreen,
      initialProvider,
    }),
    [file, initialProvider, showSuccessScreen, submitTaskBack]
  );


  if (loading) {
    return <LoadingScreen />;
  } else if (step === 'success') {
    return (
      <TaskContainer className="sign-task-container">
        <SuccessScreen onClose={submitTaskSuccess} isSigningOnMeeting={isSigningOnMeeting} />
      </TaskContainer>
    );
  } else if (errorCode !== null) {
    return (
      <TaskContainer className="sign-task-container">
        <ErrorScreen errorCode={errorCode} onCancel={submitTaskCancel} />
      </TaskContainer>
    );
  } else {
    return (
      <TaskContainer className="sign-task-container">
        <SignTaskContextProvider {...contextProps}>
          <SignTaskProviderSteps />
        </SignTaskContextProvider>
        {cancelConfirmationVisible && <CancelConfirmation {...{ hideCancelConfirmation, submitTaskCancel }} />}
      </TaskContainer>
    );
  }
};

const SignTaskProviderSteps = () => {
  const { selectedProvider, setSelectedProvider, file, onBack, onCancel, xadesFormat } = useSignTaskContext();
  const { features } = useUserContext();
  const { signing } = useConfigurationContext();


  return (
    <>
      {!selectedProvider && (
        <ProviderSelection
          {...{ file, features, signing, xadesFormat }}
          onSelection={setSelectedProvider}
          onCancel={onCancel}
          onBack={onBack}
        />
      )}
      {selectedProvider === 'Edo' && <EdoFlow />}
      {selectedProvider === 'Mszafir' && <MszafirFlow />}
      {selectedProvider === 'IdNow' && <IdNowFlow />}
      {selectedProvider === 'SimplySign' && <SimplySignFlow />}
      {selectedProvider === 'Native' && <NativeFlow />}
      {selectedProvider?.startsWith('EidEasy:') && <EidEasyFlow />}
      {selectedProvider?.startsWith('_dummy:') && <DummyProvider />}
      {selectedProvider === 'success' && <SuccessScreen />}
    </>
  );
};
